var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Animated, useWindowDimensions, } from 'react-native';
import { useRoute, useNavigation } from '@react-navigation/native';
import shallowequal from 'shallowequal';
import { getSafeBounceHeight, getDefaultHeaderHeight, getNavigationHeight, getStatusBarHeight, } from './utils';
import { createHeaderBackground as createDefaultHeaderBackground } from './createHeaderBackground';
import { createCollapsibleCustomHeaderAnimator } from './createCollapsibleCustomHeaderAnimator';
var CollapsibleHeaderType;
(function (CollapsibleHeaderType) {
    CollapsibleHeaderType[CollapsibleHeaderType["Default"] = 0] = "Default";
    CollapsibleHeaderType[CollapsibleHeaderType["SubHeader"] = 1] = "SubHeader";
})(CollapsibleHeaderType || (CollapsibleHeaderType = {}));
var useCollapsibleHeader = function (options, collapsibleHeaderType) {
    if (collapsibleHeaderType === void 0) { collapsibleHeaderType = CollapsibleHeaderType.Default; }
    var _a = options || {}, _b = _a.navigationOptions, navigationOptions = _b === void 0 ? {} : _b, _c = _a.config, config = _c === void 0 ? {} : _c;
    var _d = config.useNativeDriver, useNativeDriver = _d === void 0 ? true : _d, elevation = config.elevation, collapsedColor = config.collapsedColor, _e = config.disableOpacity, disableOpacity = _e === void 0 ? false : _e, _f = config.createHeaderBackground, createHeaderBackground = _f === void 0 ? createDefaultHeaderBackground : _f;
    var _g = navigationOptions.headerStyle, userHeaderStyle = _g === void 0 ? {} : _g;
    var _h = React.useState(userHeaderStyle), headerStyle = _h[0], setHeaderStyle = _h[1];
    React.useEffect(function () {
        if (!shallowequal(headerStyle, userHeaderStyle))
            setHeaderStyle(userHeaderStyle);
    }, [userHeaderStyle]);
    var _j = React.useState(), collapsible = _j[0], setCollapsible = _j[1];
    var _k = useWindowDimensions(), width = _k.width, height = _k.height;
    var isLandscape = height < width;
    var route = useRoute();
    var navigation = useNavigation();
    var positionY = React.useRef(new Animated.Value(0)).current;
    var onScroll = Animated.event([{ nativeEvent: { contentOffset: { y: positionY } } }], { useNativeDriver: useNativeDriver });
    var onScrollWithListener = function (listener) {
        return Animated.event([{ nativeEvent: { contentOffset: { y: positionY } } }], {
            useNativeDriver: useNativeDriver,
            listener: listener,
        });
    };
    var offsetY = React.useRef(new Animated.Value(0)).current;
    var offsetYValue = React.useRef(0);
    React.useEffect(function () {
        var listener = offsetY.addListener(function (_a) {
            var value = _a.value;
            offsetYValue.current = value;
        });
        return function () {
            offsetY.removeListener(listener);
        };
    }, []);
    var _l = route.params || {}, 
    // @ts-ignore
    subHeaderHeight = _l.collapsibleSubHeaderHeight, 
    // @ts-ignore
    customHeaderHeight = _l.collapsibleCustomHeaderHeight;
    React.useLayoutEffect(function () {
        var headerHeight = 0;
        if (customHeaderHeight) {
            headerHeight = customHeaderHeight - getStatusBarHeight(isLandscape);
        }
        else {
            if (collapsibleHeaderType === CollapsibleHeaderType.SubHeader) {
                headerHeight = subHeaderHeight || 0;
            }
            else {
                headerHeight =
                    headerStyle.height != null
                        ? headerStyle.height - getStatusBarHeight(isLandscape)
                        : getDefaultHeaderHeight(isLandscape);
            }
        }
        var safeBounceHeight = getSafeBounceHeight();
        var animatedDiffClampY = Animated.diffClamp(Animated.add(positionY, offsetY), 0, safeBounceHeight + headerHeight);
        var progress = animatedDiffClampY.interpolate({
            inputRange: [safeBounceHeight, safeBounceHeight + headerHeight],
            outputRange: [0, 1],
            extrapolate: 'clamp',
        });
        var translateY = Animated.multiply(progress, -headerHeight);
        var opacity = Animated.subtract(1, disableOpacity ? 0 : progress);
        if (collapsibleHeaderType === CollapsibleHeaderType.Default) {
            var options_1 = __assign(__assign({}, navigationOptions), { headerStyle: __assign(__assign({}, headerStyle), { transform: [{ translateY: translateY }], opacity: opacity }), headerBackground: createHeaderBackground({
                    translateY: translateY,
                    opacity: opacity,
                    backgroundColor: headerStyle === null || headerStyle === void 0 ? void 0 : headerStyle.backgroundColor,
                    collapsedColor: collapsedColor || (headerStyle === null || headerStyle === void 0 ? void 0 : headerStyle.backgroundColor),
                    elevation: elevation,
                    headerBackground: navigationOptions.headerBackground,
                }), headerTransparent: true });
            if (navigationOptions.header) {
                Object.assign(options_1, {
                    header: createCollapsibleCustomHeaderAnimator(navigationOptions.header),
                });
            }
            navigation.setOptions(options_1);
        }
        var collapsible = {
            onScroll: onScroll,
            onScrollWithListener: onScrollWithListener,
            containerPaddingTop: collapsibleHeaderType === CollapsibleHeaderType.SubHeader
                ? headerHeight
                : getNavigationHeight(isLandscape, headerHeight),
            scrollIndicatorInsetTop: headerHeight,
            positionY: positionY,
            offsetY: offsetY,
            translateY: translateY,
            progress: progress,
            opacity: opacity,
            showHeader: function () {
                offsetY.setValue(offsetYValue.current - headerHeight);
            },
        };
        setCollapsible(collapsible);
    }, [isLandscape, headerStyle, subHeaderHeight, customHeaderHeight]);
    return (collapsible || {
        onScroll: null,
        onScrollWithListener: function (e) { return null; },
        containerPaddingTop: 0,
        scrollIndicatorInsetTop: 0,
        positionY: new Animated.Value(0),
        offsetY: new Animated.Value(0),
        translateY: new Animated.Value(0),
        progress: new Animated.Value(0),
        opacity: new Animated.Value(1),
        showHeader: function () {
            // do nothing
        },
    });
};
var useCollapsibleSubHeader = function (options) {
    return useCollapsibleHeader(options, CollapsibleHeaderType.SubHeader);
};
export { useCollapsibleHeader, useCollapsibleSubHeader };
